import React, { useEffect } from "react";

import { AutocompleteArrayInput, AutocompleteInput, Filter, ReferenceInput, TextInput } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

import { REPORT_CHECK_REASONS_CHOICES } from "constants/reportCheckReasons";
import { TEAM_LEADER } from "constants/userGroups";

import { useContractors } from "utils/api";
import { useContainsURL } from "utils/hooks/useContainsURL";

const ActionClassChoices = [
  { action_class: "to_be_processed", display_name: "A traiter" },
  { action_class: "waiting", display_name: "En attente" },
  { action_class: "archived", display_name: "Archivé" },
];

const LastReportChoices = [
  { last_report_type: "need-spare-part", display_name: "Commande de pièce détachée" },
  { last_report_type: "not-processed", display_name: "Machine non traitée" },
  { last_report_type: "help-wanted", display_name: "Demande d'aide technique" },
  { last_report_type: "intervention-impossible", display_name: "Intervention impossible" },
  { last_report_type: "wrong-command", display_name: "Pièce non livrée ou non-conforme" },
  { last_report_type: "not-repaired", display_name: "Machine non réparable" },
  { last_report_type: "finished", display_name: "Terminé" },
];

const REPAIR_REPORT_CHECK_STATE = "repair_report_check_state";
const PREPARATION_VISIT_TASK_STATE = "preparation_visit_task_state";

const CustomerFilesFilter = (props) => {
  const { customerFilesStates, customerFilesTags, employees, idEmployee, connectedEmployee, ...filterProps } = props;
  const assignedEmployees = employees && employees.filter((employee) => employee.role !== "repairman");
  const isReportToCheckInterface = useContainsURL(REPAIR_REPORT_CHECK_STATE);
  const isInterventionPreparationInterface = useContainsURL(PREPARATION_VISIT_TASK_STATE);
  const optionRenderer = (choice) => (choice && choice.id ? choice.first_name + " " + choice.last_name : "N/A");
  const contractors = useContractors();

  // soft resetting filters to limit wrong redirections
  useEffect(() => {
    props.setFilter && props.setFilters({}, []);
  }, [props]);

  const reportToCheckFilters = (
    <Filter {...filterProps}>
      <TextInput label="Recherche" source="q" alwaysOn />
      <AutocompleteArrayInput
        label="Raisons"
        source="repair_report_check_reason"
        choices={REPORT_CHECK_REASONS_CHOICES}
        optionText="name"
      />
      <ReferenceInput
        label="Chef d'équipe"
        reference="employees"
        source="assignee"
        filter={{ group_name: TEAM_LEADER }}
        allowEmpty
      >
        <AutocompleteInput
          disableUnderline
          variant="outlined"
          optionValue="id"
          optionText={optionRenderer}
          emptyText="Tous les chefs d'équipe"
        />
      </ReferenceInput>
    </Filter>
  );

  const customerFilesDefaultFilters = (
    <Filter {...filterProps}>
      <TextInput label="Recherche" source="q" alwaysOn />
      <AutocompleteInput
        label="Statut du dossier"
        source="state"
        choices={customerFilesStates}
        optionText="name"
        optionValue="id"
      />
      <AutocompleteInput
        label="Action"
        source="action_class"
        choices={ActionClassChoices}
        optionText="display_name"
        optionValue="action_class"
      />
      <AutocompleteInput
        label="Opérateurs assignés"
        source="assigned_employees"
        choices={assignedEmployees}
        defaultValue={idEmployee}
        optionText="user"
        emptyText="Tous les dossiers"
      />
      <AutocompleteArrayInput label="Tags" source="tags_list" choices={customerFilesTags} optionText="text" />
      <AutocompleteInput
        label="Dernier CR"
        source="last_report_type"
        choices={LastReportChoices}
        optionText="display_name"
        optionValue="display_name"
      />
      <AutocompleteArrayInput label="Partenaire" source="contractor" choices={contractors} optionText="name" />
    </Filter>
    // parse function in RadioButton to avoid '' to be interpreted as undefined cf https://final-form.org/docs/react-final-form/types/FieldProps#parse
  );

  const interventionPreparationFilters = (
    <Filter {...filterProps}>
      <TextInput label="Recherche" source="q" alwaysOn />

      <ReferenceInput
        label="Tous les préparateurs"
        reference="employees"
        source="preparation_visit_task_assignee"
        allowEmpty
      >
        <AutocompleteInput disableUnderline variant="outlined" optionValue="id" optionText={optionRenderer} />
      </ReferenceInput>
    </Filter>
  );
  // Filters field have to be the direct child of the <Filter /> component
  // <Filter /> Also has to be the default exported component
  // hard to read, but seems corrected in future versions
  // conditional filters are also buggy: it breaks the form rendering
  return isReportToCheckInterface
    ? reportToCheckFilters
    : isInterventionPreparationInterface
    ? interventionPreparationFilters
    : customerFilesDefaultFilters;
};

const StyledCustomerFilesFilter = withStyles({
  form: {
    marginTop: "0px",
    marginLeft: "8px",
  },
})(CustomerFilesFilter);

export default StyledCustomerFilesFilter;
