import React from "react";

import { TextField } from "@material-ui/core";
import { FormControl } from "@material-ui/core";

type TextFieldProps = {
  onChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void;
  label: string;
  defaultValue?: string;
  type: string;
};

const CustomTextField: React.FunctionComponent<TextFieldProps> = ({ label, defaultValue, onChange, type = "" }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <TextField label={label} variant="outlined" type={type} onChange={onChange} defaultValue={defaultValue} />
    </FormControl>
  );
};

export default CustomTextField;
