import React from "react";

import { Box } from "@material-ui/core";

import { ServiceFee, ServiceFeeDetails } from "types/service-fees.js";

import { H3 } from "components/atoms/Typography";
import BaseModal from "components/molecules/BaseModal";

import { SERVICE_FEE } from "../../../services/constants.js";
import { OverridePriceServiceFeeStep, SelectServiceFeeStep } from "./AddServiceFeeSteps.tsx";

type AddServiceFeeProps = {
  onClose: () => void;
  submit: (element: any) => void;
  isOpen: boolean;
  quoteId: number;
  productId: number;
};

export const AddServiceFee = ({ onClose, submit, isOpen, quoteId, productId }: AddServiceFeeProps) => {
  const [step, setStep] = React.useState(1);
  const [selectedServiceFee, setSelectedServiceFee] = React.useState<ServiceFee | null>(null);

  const handleNext = (serviceFee: ServiceFee) => {
    setSelectedServiceFee(serviceFee);
    setStep(2);
  };

  const handlePrevious = () => {
    setStep(1);
  };

  const handleSubmit = (service_fee: ServiceFeeDetails, override_price: number | undefined) => {
    submit([
      {
        service_fee: {
          ...service_fee,
          ...(override_price !== undefined && { price_override: override_price * 100 }),
        },
        element_type: SERVICE_FEE,
        amount_withtax: override_price ? override_price * 100 : service_fee.price,
        name: service_fee.name,
        home_repair_product: productId,
      },
    ]);
    onClose();
  };

  return (
    <BaseModal width="md" isOpen={isOpen} onClose={onClose}>
      <Box textAlign="center">
        <H3>Frais de service</H3>
        {step === 1 && <SelectServiceFeeStep onNext={handleNext} />}
        {step === 2 && selectedServiceFee !== null && (
          <OverridePriceServiceFeeStep
            serviceFee={selectedServiceFee}
            onSubmit={handleSubmit}
            onPrevious={handlePrevious}
            quoteId={quoteId}
          />
        )}
      </Box>
    </BaseModal>
  );
};

export default AddServiceFee;
