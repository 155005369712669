import React from "react";

import { Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { Field, FieldProps, Form, Formik } from "formik";
import { ServiceFee, ServiceFeeDetails } from "types/service-fees";
import * as yup from "yup";

import Button from "components/atoms/Button";
import CustomTextField from "components/atoms/Form";
import { Body, BodyBold } from "components/atoms/Typography";

import { useServiceFeeDetails } from "utils/hooks/useServiceFees";
import { formatPrice } from "utils/price";

type FormValues = {
  service_fee: number | undefined;
  override_price: number | undefined;
};

const OverridePriceServiceFeeStep = ({
  serviceFee,
  onSubmit,
  quoteId,
  onPrevious,
}: {
  serviceFee: ServiceFee;
  onSubmit: (service_fee: ServiceFeeDetails, override_price: number | undefined) => void;
  quoteId: number;
  onPrevious: () => void;
}) => {
  const { data: serviceFeeDetails, isLoading } = useServiceFeeDetails(serviceFee.id, quoteId);
  const [isOverridingPrice, setIsOverridingPrice] = React.useState(false);

  const initialValues = {
    service_fee: serviceFee.id,
    override_price: undefined,
  };

  const validationSchema = yup.object().shape({
    override_price: yup.number().nullable(),
  });

  const submit = (values: FormValues) => {
    if (!serviceFeeDetails) {
      return;
    }
    onSubmit(serviceFeeDetails, values.override_price);
  };

  return (
    <Box>
      {isLoading && <Body fontStyle="italic">Récupération du prix des frais de service</Body>}
      {serviceFeeDetails && (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ isSubmitting, isValid }) => (
            <Form translate={null}>
              <Box my={4}>
                <Box display="flex" justifyContent="space-around" alignItems="bottom" flex={20} alignSelf="center">
                  <Box textAlign="justify">
                    <BodyBold>{serviceFeeDetails.name}</BodyBold>
                    <Body>{serviceFeeDetails.description}</Body>
                  </Box>
                  <Box>
                    <Field name="override_price">
                      {({ field, form }: FieldProps) => (
                        <Box>
                          {!isOverridingPrice && (
                            <Button
                              dimension="small"
                              version="invisible"
                              onClick={() => {
                                form.setFieldValue("override_price", serviceFeeDetails.price);
                                setIsOverridingPrice(true);
                              }}
                            >
                              <Box display="flex">
                                <BodyBold mr={1}>{formatPrice(serviceFeeDetails.price)}</BodyBold>
                                <EditIcon />
                              </Box>
                            </Button>
                          )}
                          {isOverridingPrice && (
                            <CustomTextField
                              {...field}
                              label="Prix modifié"
                              type="number"
                              defaultValue={
                                serviceFeeDetails.price !== undefined
                                  ? (serviceFeeDetails.price / 100).toString()
                                  : undefined
                              }
                              onChange={(event) => form.setFieldValue("override_price", event.target.value)}
                            />
                          )}
                        </Box>
                      )}
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-around">
                <Button dimension="small" version="ghost" onClick={onPrevious}>
                  Retour
                </Button>
                <Button disabled={isSubmitting || !isValid} dimension="small" version="fancy" type="submit">
                  Ajouter les frais de service
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default OverridePriceServiceFeeStep;
