import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";

import Moment from "moment";
import { toastr } from "react-redux-toastr";

import Avatar from "components/atoms/Avatar";
import Caption from "components/atoms/Typography/Caption";

import QuoteHeaderStyles from "./QuoteHeaderStyles.js";
import QuoteLink from "./QuoteLink";

function copyPaymentLink(event, quoteUuid) {
  navigator.clipboard.writeText(`https://murfy.fr/devis/proposition-devis/${quoteUuid}`);
  event.stopPropagation();
  toastr.success("Succès", "Lien de proposition de devis copié");
}

const useStyles = makeStyles(QuoteHeaderStyles);

export default ({ record, isDraft = false }) => {
  const classes = useStyles();

  const version = record.version + 1 + isDraft;

  const getDate = () => {
    if (isDraft) return new Date();
    if (record.quote_accepted) return record.accepted_at;
    if (record.quote_refused) return record.refused_at;
    return record.created_at;
  };

  const getFormattedDate = () => Moment(getDate()).locale("fr").format("DD/MM/YYYY");

  return (
    <div className={classes.detailDisplayField}>
      <div className={classes.quoteHeaderText}>
        {record.created_by && <Avatar id={record.created_by} name={record.created_by_name} mr={1} mt={"-5px"} />}
        <Typography id="new-quote-snapshot" variant="subtitle2" component="span">
          Devis&nbsp; - {version} &nbsp;
        </Typography>

        {record.finalization_context.context_type === "visit" && <Caption>(Finalisé pendant un passage)</Caption>}
        <span className={classes.quoteHeaderDate}>{getFormattedDate()}</span>
        <div>
          {!isDraft && record.uuid && (
            <LinkIcon className={classes.quoteLinkIcon} onClick={(e) => copyPaymentLink(e, record.uuid)} />
          )}
        </div>
      </div>
      <div className={classes.quoteHeaderStatus}>{isDraft ? "Brouillon" : <QuoteLink quote={record} />}</div>
    </div>
  );
};
