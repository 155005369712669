export default {
  detailDisplayField: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 0px 16px 0px",
    "&:last-child": {
      paddingRight: 0,
    },
  },
  quoteHeaderText: {
    display: "inline-flex",
    alignItems: "center",
  },
  quoteHeaderDate: {
    color: "#8795B3",
  },
  quoteLinkIcon: {
    fontSize: 20,
    color: "#8795B3",
    marginLeft: 16,
  },
  quoteHeaderStatus: {
    color: "#8795B3",
    fontWeight: 600,
    fontSize: 13,
  },
};
