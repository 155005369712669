import React from "react";

import { Box } from "@material-ui/core";

import { Field, FieldProps, Form, Formik } from "formik";
import { ServiceFee } from "types/service-fees";
import * as yup from "yup";

import Button from "components/atoms/Button";
import Select from "components/atoms/Select";

import { useServiceFees } from "utils/hooks/useServiceFees";

const SelectServiceFeeStep = ({ onNext }: { onNext: (serviceFee: ServiceFee) => void }) => {
  const { data: serviceFees } = useServiceFees();

  const initialValues = {
    service_fee: serviceFees && serviceFees[0] && serviceFees[0].id,
  };

  const validationSchema = yup.object().shape({
    service_fee: yup.number().required("Ce champ est requis"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        if (serviceFees && values.service_fee !== undefined) {
          const selectedFeeId = parseInt(values.service_fee.toString());
          const serviceFee = serviceFees.find((serviceFee) => serviceFee.id === selectedFeeId);
          if (serviceFee) {
            onNext(serviceFee);
          }
        }
      }}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid }) => (
        <Form translate={null}>
          <Box my={4} display="flex" alignItems="bottom">
            <Box textAlign="left" flex={80} justifyContent="center" alignItems="center">
              <Field name="service_fee">
                {({ field, form }: FieldProps) => (
                  <Select
                    {...field}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      form.setFieldValue("service_fee", event.target.value)
                    }
                    options={
                      (serviceFees &&
                        serviceFees.map((serviceFee: ServiceFee) => ({
                          label: serviceFee.name,
                          value: serviceFee.id.toString(),
                        }))) ||
                      []
                    }
                    label="Type de frais"
                    defaultValue={field.value}
                  />
                )}
              </Field>
            </Box>
          </Box>
          <Box>
            <Button disabled={isSubmitting || !isValid} dimension="small" version="fancy" type="submit">
              Continuer
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SelectServiceFeeStep;
