import React from "react";

import { useContractors } from "utils/api";

import ColorChip from "../ColorChip";

type ContractorTagProps = { id: number };

export default ({ id }: ContractorTagProps) => {
  const contractors = useContractors();
  const contractor = contractors && contractors.find((item: any) => item.id === id);

  const svg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#855BF8" />
      <g clip-path="url(#clip0_1550_726)">
        <path
          d="M12 10C13.3807 10 14.5 8.88071 14.5 7.5C14.5 6.11929 13.3807 5 12 5C10.6193 5 9.5 6.11929 9.5 7.5C9.5 8.88071 10.6193 10 12 10Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.625 17.5C17.625 14.3934 15.1066 11.875 12 11.875C8.89344 11.875 6.375 14.3934 6.375 17.5"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 17.5L13.25 15.9375L12 11.875L10.75 15.9375L12 17.5Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1550_726">
          <rect width="15" height="15" fill="white" transform="translate(4.5 3.75)" />
        </clipPath>
      </defs>
    </svg>
  );

  if (!contractor) return null;

  return (
    <ColorChip
      label={contractor.name}
      icon={svg}
      style={{ fontWeight: "600", fontFamily: "Poppins", background: "#F4F3F8" }}
    />
  );
};
