import React from "react";

import { Box } from "@material-ui/core";

import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import Button from "components/atoms/Button";
import CustomTextField, { CustomCheckbox } from "components/atoms/Form";
import Select from "components/atoms/Select";
import { H3 } from "components/atoms/Typography";
import BaseModal from "components/molecules/BaseModal";

import { useSkuSuppliers } from "utils/api";

export default function AddSparePartASWORefSelection({
  isOpen,
  handleCloseSparePart,
  handleReferenceChange,
  goToAswoModalNoReference,
  onSearchClick,
  setSupplier,
  setSimilarity,
}) {
  const supplierChoices = useSkuSuppliers();

  const initialValues = {
    supplier: supplierChoices && supplierChoices[0] && supplierChoices[0].id,
    reference: "",
    similarity: false,
  };
  const handleValidate = (values) => {
    handleReferenceChange(values.reference);
    setSupplier(supplierChoices.find((supplier) => supplier.id === values.supplier));
    setSimilarity(values.similarity);
    onSearchClick(values);
  };

  const handleManualReference = () => {
    goToAswoModalNoReference();
  };

  const validationSchema = yup.object().shape({
    supplier: yup.number().required("Le fournisseur est obligatoire"),
    reference: yup.string().required("La référence est obligatoire"),
  });
  return (
    <BaseModal isOpen={isOpen} onClose={handleCloseSparePart} title="Détail de la pièce">
      <Box textAlign="center">
        <H3>Détail de la pièce</H3>
        <Box mt={2}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleValidate}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {({ isSubmitting, isValid, values }) => (
              <Form translate={null}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Field name="supplier">
                    {({ field, form }) => (
                      <Box width={200} mr={1}>
                        <Select
                          {...field}
                          onChange={(event) => form.setFieldValue("supplier", event.target.value)}
                          options={
                            (supplierChoices &&
                              supplierChoices.map((supplier) => ({ label: supplier.name, value: supplier.id }))) ||
                            []
                          }
                          label="Fournisseur"
                          defaultValue={field.value}
                          testId="supplier-select"
                        />
                      </Box>
                    )}
                  </Field>
                  <Field name="reference">
                    {({ field, form }) => (
                      <CustomTextField
                        {...field}
                        label="Référence"
                        onChange={(event) => form.setFieldValue("reference", event.target.value)}
                      />
                    )}
                  </Field>
                </Box>
                <Field name="similarity">
                  {({ field, form }) => (
                    <CustomCheckbox
                      {...field}
                      label="Inclure les référence similaires"
                      onChange={(event) => form.setFieldValue("similarity", event.target.checked)}
                    />
                  )}
                </Field>
                <Button disabled={isSubmitting || !isValid} dimension="jumbo" version="fancy" type="submit">
                  Rechercher
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Box textAlign="center" py={2}>
          <Button id="no-reference-aswo" version="invisible" onClick={handleManualReference}>
            Pas de référence aswo ?
          </Button>
        </Box>
      </Box>
    </BaseModal>
  );
}
