import { useQuery } from "@tanstack/react-query";
import { get } from "lib/http";
import { ServiceFee, ServiceFeeDetails } from "types/service-fees";

export const useServiceFees = () => {
  const getServiceFees = async () => {
    const url = `/payment/service-fees/`;
    const serviceFees: ServiceFee[] = await get(url);
    return serviceFees;
  };

  const { data, isLoading, isError } = useQuery(["get-service-fees"], getServiceFees);

  return { data, isLoading, isError };
};

export const useServiceFeeDetails = (serviceFeeId: number, quoteId: number) => {
  const getServiceFeeDetails = async () => {
    const url = `/payment/service-fees/${serviceFeeId}/?quote_id=${quoteId}`;
    const serviceFeeDetails: ServiceFeeDetails = await get(url);
    return serviceFeeDetails;
  };

  const { data, isLoading, isError } = useQuery(
    ["get-service-fee-details", serviceFeeId, quoteId],
    getServiceFeeDetails
  );

  return { data, isLoading, isError };
};
