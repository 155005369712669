import React from "react";

import { AutocompleteInput } from "react-admin";

import { Box, makeStyles } from "@material-ui/core";

import { useContractors } from "utils/api";

/**
 * Display inputs for a customer file Contractor informations
 * Should be used inside a react-admin or react-final-form Form.
 */

const useStyles = makeStyles({
  suggestionsContainer: {
    zIndex: 1301,
  },
});

const ContractorInformationInputs = () => {
  const contractors = useContractors();
  const classes = useStyles();
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="320px">
          <AutocompleteInput
            label="Nom"
            source="contractor"
            fullWidth
            choices={contractors}
            optionValue="id"
            optionText="name"
            emptyText="Aucun"
            allowEmpty
            options={{
              suggestionsContainerProps: {
                className: classes.suggestionsContainer, // fix suggestions display on top of the dialog
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ContractorInformationInputs;
