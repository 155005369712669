import React from "react";

import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";

type CheckboxFieldProps = {
  onChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void;
  label: string;
  defaultValue?: boolean;
};

const CustomTextField: React.FunctionComponent<CheckboxFieldProps> = ({ label, onChange }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <FormControlLabel control={<Checkbox onChange={onChange} />} label={label} />
    </FormControl>
  );
};

export default CustomTextField;
