import React, { useState } from "react";

import { SimpleForm } from "react-admin";

import { Box, Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import { CustomerFileDetailed } from "types/customer-files";

import ContractorInformationInputs from "components/ContractorInformationInputs";
import { SimpleValidateToolbar } from "components/FormToolbars";
import ContractorTag from "components/atoms/ContractorTag/ContractorTag";

interface CustomerFileContractorDisplayProps {
  customerFile: CustomerFileDetailed;
  updateCustomerFile: (id: number, values: CustomerFileDetailed) => void;
}

const CustomerFileContractorDisplay: React.FC<CustomerFileContractorDisplayProps> = ({
  customerFile,
  updateCustomerFile,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const update = (values: CustomerFileDetailed) => {
    handleClose();
    updateCustomerFile(customerFile.id, values);
  };

  const contractorDialog = (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>{customerFile.contractor ? "Modifier le partenaire" : "Ajouter un partenaire"}</DialogTitle>
      <DialogContent>
        <SimpleForm
          record={customerFile}
          toolbar={<SimpleValidateToolbar onSave={update} size="large" version="right" label="Valider" />}
        >
          <ContractorInformationInputs />
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );

  return customerFile.contractor ? (
    <Box mx={3}>
      <ContractorTag id={customerFile.contractor} />
      <Button onClick={handleOpen} style={{ marginLeft: "10px" }}>
        Modifier
      </Button>
      {contractorDialog}
    </Box>
  ) : (
    <Box mx={3}>
      <Button onClick={handleOpen}>Ajouter un partenaire</Button>
      {contractorDialog}
    </Box>
  );
};

export default CustomerFileContractorDisplay;
