import React from "react";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
// UI
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";

//Types
import { QuoteElement } from "types/quote-element";

//Utils
import { formatPriceCompact } from "utils/formatting";

type ServiceFeeProps = {
  element: QuoteElement;
  remove: (content: Record<string, any>) => void;
  index: number;
};

const ServiceFee = ({ element, remove, index }: ServiceFeeProps) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
      <Typography variant="body1">{element.name}</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {element.amount_withtax ? formatPriceCompact(element.amount_withtax) : ""}
        </Typography>
        {remove && (
          <IconButton onClick={() => remove(element)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ServiceFee;
