import React from "react";

import { Box, Button } from "@material-ui/core";

import { QuoteElement } from "types/quote-element";

import { REPAIR_BONUS_DISCOUNT } from "../../services/constants";

type AddDiscountRepairBonusProps = {
  productId: number;
  quoteUUID: string;
  quoteElements: QuoteElement[];
  addDiscountRepairBonus: (quoteUUID: string, productId: number) => void;
};

function isRepairBonusDiscount(quoteElement: QuoteElement) {
  if (quoteElement.element_type && quoteElement.element_type === REPAIR_BONUS_DISCOUNT) {
    return true;
  } else return false;
}

function CanApplyRepairBonusDiscountOnProduct(quoteElements: QuoteElement[], productId: number) {
  if (!quoteElements) {
    return true;
  }

  let applied_repair_bonuses_discount_on_product = quoteElements.filter(
    (element: QuoteElement) => element.home_repair_product === productId && isRepairBonusDiscount(element)
  );

  return applied_repair_bonuses_discount_on_product.length === 0;
}

export const AddDiscountRepairBonus = ({
  productId,
  quoteUUID,
  quoteElements,
  addDiscountRepairBonus,
}: AddDiscountRepairBonusProps) => {
  const onClickAddDiscountRepairBonus = (quoteUUID: string, productId: number) => {
    addDiscountRepairBonus(quoteUUID, productId);
  };

  return (
    <div>
      {CanApplyRepairBonusDiscountOnProduct(quoteElements, productId) && (
        <Box marginTop={1}>
          <Button
            className={`discount-repair-bonus-${productId}`}
            variant="outlined"
            size="small"
            onClick={() => onClickAddDiscountRepairBonus(quoteUUID, productId)}
          >
            <small>Ajouter la remise bonus garanti</small>
          </Button>
        </Box>
      )}
    </div>
  );
};
